<template>
    <div class="edit-timetracker">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.timeTrackerUpdate')"
            :title="$t('messages.timeTrackerUpdate')"
            @submitPressed="submitPressed"
        />
        <time-tracker-form
            v-if="timeTracker"
            :timeTrackerObject="timeTracker"
            :action="action"
            @clearAction="action = null"
        />
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TimeTrackerForm from "./components/TimeTrackerForm.vue";

export default {
    components: {
        TitleBar,
        TimeTrackerForm
    },
    data() {
        return {
            timeTracker: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$TimeTracks.getResource({id}, "time_list").then((response) => {
                this.timeTracker = response.data;
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
