<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols-lg="6">
                    <b-form-group
                        :class="{ row, error: v$.typeSelect.$errors.length }"
                        :label="$t('forms.type')"
                        label-for="type"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="type"
                            v-model="typeSelect"
                            :options="types"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.typeSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.projectSelect.$errors.length }"
                        :label="$t('forms.project')"
                        label-for="projectSelect"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="projectSelect"
                            v-model="projectSelect"
                            :options="projects"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.projectSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.date')"
                        label-for="workDate"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="workDate"
                            v-model="workDate"
                            :enableTimePicker="true"
                            range
                            class="form-input"
                            format="dd.MM.yyyy HH:mm"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.workDate.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        v-if="$route.query.start === 'true'"
                        class="row"
                        :label="$t('forms.workTime')"
                        label-for="workTime"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input v-model="workTime"/>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.remark')"
                        label-for="remark"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input v-model="timeTracker.remark"/>
                    </b-form-group>
                </b-col>
                <b-col cols-lg="6"></b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import vSelect from "vue-select";

export default {
    components: {
        Datepicker,
        vSelect
    },
    props: {
        timeTrackerObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getResourceList(this.$Projects, this.projects)
        this.getEnumList(["time_track_type"], this.types);
        if ('id' in this.timeTracker) {
            this.projectSelect = {
                label: this.timeTracker.project.name,
                id: this.timeTracker.project['@id']
            };
            this.typeSelect = {
                label: this.$helper.getEnumTranslation('time_track_type', this.timeTracker.type, this.$i18n.locale),
                id: this.timeTracker.type
            };
        }
        if (this.$route.query.start === 'true') {
            this.workDate[0] = new Date()
            this.setEndTime()
        }

    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            projectSelect: {required},
            typeSelect: {required},
            workDate: {required},
        };
    },
    data() {
        return {
            timeTracker: this.timeTrackerObject,
            types: [],
            workDate: [this.timeTrackerObject.fromDate, this.timeTrackerObject.toDate],
            typeSelect: {},
            projects: [],
            projectSelect: {},
            interval: null,
            workTime: null
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                this.timeTracker.fromDate = this.$helper.formatDateTimeToSend(this.workDate[0])
                this.timeTracker.toDate = this.$helper.formatDateTimeToSend(this.workDate[1])
                if (this.typeSelect) {
                    this.timeTracker.type = this.typeSelect.id
                }
                if (this.projectSelect) {
                    this.timeTracker.project = this.projectSelect.id
                }
                if (this.action === "add") {
                    this.create(this.$TimeTracks, this.timeTracker, this.$t("messages.timeTrackerCreated"), null, this.success, this.error);
                }
                if (this.action === "update") {
                    this.$emit("clearAction");
                    this.update(this.$TimeTracks, this.timeTracker.id, this.timeTracker, this.$t("messages.timeTrackerUpdated"), null, this.success, this.error);
                }
            } else {
                this.$emit("clearAction");
            }
        },
        setEndTime() {
            const _self = this
            this.interval = setInterval(function () {
                _self.workDate[1] = new Date()
                _self.workTime = _self.calcWorkTime(_self.workDate[0], _self.workDate[1])
            }, 1000)
        },
        calcWorkTime(start, end) {
            let diff = new Date(end) - new Date(start);
            if (diff <= 0) {
                return '0'
            }

            let hours = Math.floor(diff / 1000 / 60 / 60);
            let minutes = Math.floor((diff / 1000 / 60 / 60 - hours) * 60);
            let seconds = Math.floor((diff / 1000 / 60 / 60 - hours) * 60 * 60) - (minutes * 60);

            return hours + ' hours ' + minutes + ' minutes ' + seconds + ' seconds';
        },
        success() {
            this.$router.push(`/time-tracking`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

